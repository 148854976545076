<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Tasks</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Tasks"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.taskForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Task</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="tasks"
            no-data-text="No tasks"
            :items-per-page="-1"
          >
            <template v-slot:item.status="{ item }">
              <v-switch
                v-model="fields.status"
                @click="openSwitch(item)"
              ></v-switch>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.taskForm.openForm(item.id)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <!-- <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <TaskForm ref="taskForm" />
    <v-dialog scrollable v-model="deleteTask.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Task</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteTask.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TaskForm from "./components/TaskForm";

export default {
  components: {
    TaskForm,
  },

  data() {
    return {
      deleteTask: {
        dialog: false,
        task: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
      fields: {
        status: 0,
      },

      tableHeaders: [
        { text: "Title", value: "task.name" },
        { text: "Dog", value: "dog.name" },
        { text: "Date", value: "complete_by_date" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    tasks() {
      let tasks = this.$store.state.lhl.tasks["tasks"];

      return tasks;
    },
  },

  methods: {
    openSwitch(task) {
      this.loading = true;
      this.errors = {};

      this.fields.status = task.status ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        taskId: task.id,
      };

      this.$store
        .dispatch("lhl/tasks/saveCompleteTask", payload)
        .then(() => {})
        .catch();
    },

    openDelete(task) {
      this.deleteTask.task = task;
      this.deleteTask.dialog = true;
    },

    resetDelete() {
      this.deleteTask.dialog = false;
      this.deleteTask.task = {};
      this.deleteTask.loading = false;
    },

    saveDelete() {
      this.deleteTask.loading = true;

      this.$store
        .dispatch("lhl/tasks/deleteTask", {
          appId: this.$route.params.id,
          taskId: this.deleteTask.task.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteTask.loading = false;
        });
    },
  },
};
</script>
